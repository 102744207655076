import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CarouselCard from './CarouselCard';

const CarouselItem = () => {

  const [recentProducts, setRecentProducts] = useState([]);

  const frameColor = {
    black: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-black.png?alt=media&token=6f2c3371-eeb8-4d81-8706-c016533fbb91',
  }

  const [frame, setFrame] = useState(frameColor.black); 
  
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";


  useEffect(() => {
    const getProducts = async () => {
      try {
        let queryString = '';
        const res = await axios.get(`${BASE_URL}/api/shop/all-prints${queryString}`);
        const sortedItems = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setRecentProducts(sortedItems)
      } catch (err) {
        console.log(err)   
      } 
    }
    getProducts();
    
  },[]);

  return (
    <div className='carousel-card-container' style={{display: 'flex'}}>
      {recentProducts.slice(0, 3).map(item=> (
        <CarouselCard item={item} key={item._id} frame={frame}/>
      ))}
    </div>
  )
}

export default CarouselItem