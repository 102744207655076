import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Announcement from '../components/Announcement';
import Slider from '../components/Slider';
import NewArrivals from '../components/NewArrivals';
import Footer from '../components/Footer';
import Products from '../components/Products';

const Home = () => {
  const frameColor = {
    black: 'https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/frame-black.png?alt=media&token=6f2c3371-eeb8-4d81-8706-c016533fbb91',
  }

  const [frame, setFrame] = useState(frameColor.black); 

  return (
    <div className='main-container'>
      <Navbar /> 
      <Announcement />
      <Slider />
      <NewArrivals frame={frame} />
      <Footer />
    </div>
  )
}

export default Home