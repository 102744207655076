import React from 'react'
import './styles/mobile-menu.css'

const MobileMenu = ({ isOpen, handleMenuToggle, handleCategoriesClick }) => {
  if (!isOpen) return null;

  return (
    <div className="mobile-navigation-overlay">
      <div className='mobile-navigation-panel'>
        <ul className="mobile-navigation-links">
          <li><a href="#home" onClick={handleMenuToggle}>Shop All</a></li>
          <li><a href="#about" onClick={handleMenuToggle}>New Arrivals</a></li>
          <li><a href="#services" onClick={handleCategoriesClick}>Categories</a></li>
          <li><a href="#contact" onClick={handleMenuToggle}>Membership</a></li>
        </ul>
      </div>
    </div>
  )
}

export default MobileMenu