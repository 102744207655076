import React from 'react'
import './styles/category-menu.css'
import { MdArrowBack } from 'react-icons/md';


const CategoryMenu = ({handleBack}) => {
  return (
    <div className="category-navigation-overlay">
      
      <form action="" className='cat-nav-back'>
        <button className="back-button" onClick={() => handleBack()}>
          <MdArrowBack size="24px" color="black" /> 
        </button> 
        <span>Categories</span>
      </form>
      
      <ul className="category-navigation-links">
        <li><a href="#b&w" onClick={handleBack}>Black & White</a></li>
        <li><a href="#travel" onClick={handleBack}>Travel</a></li>
        <li><a href="#nature" onClick={handleBack}>Nature</a></li>
        <li><a href="#city" onClick={handleBack}>City</a></li>
      </ul>
      
    </div>
  )
}

export default CategoryMenu