import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../redux/userRedux'
import { useNavigate } from 'react-router-dom'

const Profile = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, isMember, isFetching, error } = useSelector(state => state.user);

  if (isFetching) return <div>Loading...</div>;
  if (error) return <div>Error: Could not load user data. Please try again later.{error}</div>;

  const handleLogout = () => {
    dispatch(logout());
    navigate('/')
  };

  return (
    <div className='main-container'>
      <Navbar /> 
      <Announcement />
      <div>
        <button onClick={handleLogout}>SIGN OUT</button>
        <h1>Welcome, {currentUser?.name}</h1>
        {isMember && <p>You are a premium member!</p>}
      </div>
    </div>
  )
}

export default Profile