import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/shop-prints.css'
import { FaRegWindowClose } from 'react-icons/fa';

const categories = [
  { name: "Shop All", url: "/shop/all-prints/", image: "https://firebasestorage.googleapis.com/v0/b/print-shop-635e7.appspot.com/o/HERO%20IMAGE%2F215SKYLINE-BW.jpg?alt=media&token=797c3ffd-59a8-4015-a158-59253817cc1b" },
  { name: "New Arrivals", url: "/shop/new-arrivals/", image: "image_url_for_New_Arrivals" },
  { name: "Black & White", url: "/shop/black-white/", image: "image_url_for_Black_White" },
  { name: "Travel", url: "/shop/travel/", image: "image_url_for_Travel" },
  { name: "Nature", url: "/shop/nature/", image: "image_url_for_Nature" },
  { name: "City", url: "/shop/city/", image: "image_url_for_Nature" },

];

const ShopPrintsPage = () => {
  const [selectedImage, setSelectedImage] = useState(categories[0].image);
  const navigate = useNavigate();
  const handleExit = () => {
    navigate(-1); // Use history.goBack() to return to the previous page
  };
  
  const handleCategoryHover = (image) => {
    setSelectedImage(image);
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div></div>
      <div className='shop-list f-v' style={{ width: '540px', overflowY: 'auto' }}>
        <div className=''>
          <span className='modal-x-nav'>
            <button className='list-close js-menu-shop-close' onClick={handleExit}>
              <FaRegWindowClose />
            </button>
          </span>
        </div>
        <ul className='shop-prints-navigation'>
        {categories.map((category) => (
            <li key={category.name} onMouseOver={() => handleCategoryHover(category.image)}>
              <a href={category.url} className='nav-link'>
                {category.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={selectedImage} alt="Selected" />
      </div>
    </div>
  );
};

export default ShopPrintsPage;
