import React from 'react'
import Navbar from '../components/Navbar'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'

const Terms = () => {
  return (
    
    <div>
      <Navbar/>
      <Announcement/>
      <h1>Terms of Service</h1>
      
      <p>These Terms of Service ("Terms") govern your access to and use of the [Your Company Name] website, apps, APIs, widgets, and any other digital touchpoints (collectively, the “Site”) operated by [Your Company Name], its parents, affiliates, or subsidiaries (collectively “we,” “us,” or “our”), including the services we provide, such as the sale of framed photography, fine art, and other physical goods (“Services”). Please read these Terms carefully and contact us if you have any questions. By accessing or using the Site or Services, you agree to be bound by these Terms and our Privacy Policy. We reserve the right to update or change these Terms by posting updates to the Site. It is your responsibility to check this page periodically for changes. Continued use of the Site or Services constitutes your acceptance of any changes.</p>
      
      <h2>1. Use of Services</h2>
      <p>You may use the Site only if you can form a legally binding contract and in compliance with these Terms and all applicable laws. You can browse the Site or create an account (“Account”). You are not required to have an Account to order products or use the Services. You must provide true, accurate, current, and complete information. We may deny or terminate your access if we believe your information is inaccurate. You are not permitted to use the Site if prohibited by U.S. sanctions or laws. Use by anyone under 13 is not allowed. If you are in the European Economic Area, you may use the Site only if you are over the legal age to provide consent to data processing. Use of the Site may involve downloading software, which we may update automatically.</p>
      <p>We grant you a limited, non-exclusive, non-transferable license to use our Services. Commercial use of the Site or Services is prohibited unless you purchase a commercial account by contacting support@[yourcompany.com].</p>
      
      <h2>2. Accounts</h2>
      <p>Creating an Account requires your name, email address, and a password (“Account Information”), which you must keep confidential. You are responsible for all use of your Account. We may take necessary actions to ensure security, including terminating your Account or changing your password. Notify us immediately if your Account is accessed without authorization. You may not register for multiple Accounts or on behalf of others without their authorization.</p>
      <p>We may use third-party service providers for advertising and data collection. These providers may collect information about your Account and Site visits to tailor marketing messages. This information may include your device’s IP address, user agent, and email addresses. For more information on these practices, visit <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>.</p>
      
      <h2>3. Orders</h2>
      <p>These Terms govern any order you make through the Site (“Order”). Placing an Order is an offer to purchase products, which we may accept by processing payment and shipping the product. Order confirmation does not signify acceptance. We may decline or limit your Order. Orders are accepted upon shipment of the products. Title and risk of loss pass to you when the product is provided to the carrier. Estimated shipping dates do not include transit time. We ship globally but only framed products within the United States.</p>
      
      <h2>4. Returns</h2>
      <p>Products are made to order, and we do not accept refunds, returns, or exchanges. Orders cannot be changed or canceled after 24 hours. For damaged products, send photos to support@[yourcompany.com] within 72 hours of delivery. We may choose to repair or replace damaged products.</p>
      
      <h2>5. Payment, Pricing, and Products</h2>
      <p>To place an Order, provide the necessary information, including shipping and billing details. Payment methods include credit cards and other options available on the Site. You must have authorization to use the payment method. Prices and availability are subject to change. We are not responsible for typographical errors. Shipping and handling are included in prices unless otherwise noted. Taxes will be added as applicable.</p>
      
      <h2>6. Ownership of Technology and Content</h2>
      <p>The software, code, methods, and systems used for the Site and Services (“Our Technology”) are protected by intellectual property laws and owned by us. You may not copy, modify, reproduce, or redistribute Our Technology without permission. Certain names, logos, and materials on the Site are our trademarks. Your right to use materials accessed or downloaded through the Site is subject to these Terms.</p>
      
      <h2>7. User Submitted Materials</h2>
      <p>If you submit materials for us to print and frame (“User Submitted Materials”), you retain ownership but grant us a license to use them to provide our Services. You must have the rights to the materials and ensure they do not infringe on others' rights. You consent to the use of likenesses and have obtained necessary permissions for identifiable individuals. We may filter, refuse, or disclose materials as necessary.</p>
      
      <h2>8. General Rules of User Conduct</h2>
      <p>You agree not to use the Site or Services for any illegal activities or in ways that violate these Terms. This includes, but is not limited to, uploading harmful content, unauthorized access, generating spam, or misrepresenting your identity.</p>
      
      <h2>9. Modifications to / Discontinuance of the Site and/or Services</h2>
      <p>We may modify or discontinue the Site or Services without notice. If you object to changes, your only recourse is to stop using the Site or Services. Continued use indicates acceptance of changes.</p>
      
      <h2>10. Disclaimers</h2>
      <p>The Site and Services are provided "as is" without warranties of any kind. We disclaim all warranties, including merchantability and fitness for a particular purpose. We are not responsible for User Submitted Materials or any damages resulting from the use of the Site or Services.</p>
      
      <h2>11. Limitation of Liability</h2>
      <p>Our liability is limited to the total amount of the Order. We are not liable for indirect, incidental, or consequential damages. Some jurisdictions do not allow these limitations, so they may not apply to you.</p>
      
      <h2>12. Indemnification</h2>
      <p>You agree to indemnify and hold us harmless from any claims arising from your use of the Site or Services, violation of these Terms, or User Submitted Materials.</p>
      
      <h2>13. Dispute Resolution</h2>
      <p>Disputes will be resolved through binding arbitration or small claims court. You waive the right to a trial by jury or to participate in a class action.</p>
      
      <h2>14. Governing Law</h2>
      <p>These Terms are governed by the laws of the State of California.</p>
      
      <h2>15. Notice</h2>
      <p>You are responsible for providing a current email address for notices. Notices will be deemed given when sent to the last provided email address.</p>
      
      <h2>16. Assignment</h2>
      <p>These Terms may not be transferred by you but may be assigned by us.</p>
      
      <h2>17. Waiver</h2>
      <p>Failure to enforce any provision of these Terms does not constitute a waiver.</p>
      
      <h2>18. Parties</h2>
      <p>These Terms are a contract between you and [Your Company Name].</p>
      
      <h2>19. Severability & Entire Agreement</h2>
      <p>These Terms, along with our Privacy Policy, constitute the entire agreement. If any provision is deemed invalid, the remaining provisions remain in effect.</p>
      
      <p>For questions about these Terms, contact us at support@[yourcompany.com].</p>
      <Footer/>
    </div>
  )
}

export default Terms