import React from 'react';
import './styles/navbar.css';
import { Badge } from '@mui/icons-material';
import { ShoppingCartOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { Link} from 'react-router-dom';
import { logout } from '../redux/userRedux';
import { useNavbarContext } from './NavbarContext';
import { FaRegUser } from 'react-icons/fa6';
import {MdOutlineMenu} from 'react-icons/md';
import {MdClose} from 'react-icons/md';
import MobileMenu from './MobileMenu';
import CategoryMenu from './CategoryMenu';

const Navbar = () => {
  const {showNav} = useNavbarContext();
  const { setShowNav } = useNavbarContext();
  const [lastY, setLastY] = useState(window.scrollY);
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);


  // Toggle menu open/close state
  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    setIsCategoriesOpen(false);
  };

  const handleCategoriesClick = () => {
    setIsCategoriesOpen(true);
  };

  const handleBack = () => {
    setIsCategoriesOpen(false);
  };
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < lastY && currentScrollY > 400) {
        setShowNav(true)
      } else {
        setShowNav(false)
      }
      setLastY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [lastY, setShowNav]);

  const Hamburger = <MdOutlineMenu className="HamburgerMenu"
            size="25px" color="black"/>
  
  const Close = <MdClose className="HamburgerMenu"
            size="25px" color="black" />

  const quantity = useSelector(state => state.cart.quantity);
  const currentUser = useSelector((state) => state.user.currentUser);

  console.log('handleBack:', handleBack);
  return (
    <header className= "main-site-header">
      <nav className='wrapper'>
        <div className='containerNavbar'>
          <div className='leftNavbar'>
            <div className='desktop-navigation-content'>
              <ul className='navigation-menu-list'>
                <li className='navigation-item'>
                  <Link to="/shop-prints" className='js-menu-shop-open'>Shop Prints</Link>
                </li>
                {!currentUser?
                  (
                    <li className='navigation-item'>
                      <Link to='/register' className='js-membership-modal-open'>MEMBERSHIP</Link>
                    </li>
                  ) : (
                    ''
                  )
                }
              </ul>
            </div>
            <div className="mobile-navigation-content">
              {/* Hamburger Icon */}
              <div
                className={`mobile-navigation-hamburger-menu ${isOpen ? 'open' : ''}`}
                onClick={handleMenuToggle}
              >
                {isOpen ? Close : Hamburger}
              </div>

              {/* Overlay Menu */}
              {isOpen && (
                <MobileMenu 
                  isOpen={isOpen} 
                  handleMenuToggle={handleMenuToggle} 
                  handleCategoriesClick={handleCategoriesClick}/>
              )}
              {/* Category Overlay Menu */}
              {isCategoriesOpen && <CategoryMenu handleBack={handleBack} />}
            </div>
          </div> 
          <div className='centerNavbar'>
            <Link to='/'>
              <h1 className='logoNavbar'>Framed by Create</h1>
            </Link>
          </div>
          <div className='rightNavbar'>
            <div className='desktop-navigation-content'>
              <ul className='navigation-menu-list'>
                <li className='navigation-item'>
                  <div className='navigation-item-link current-country__toggle'>EN</div>
                </li>
                { currentUser? 
                  (
                    <li className='navigation-item nav-logout'>
                      <Link to='/pages/settings'><FaRegUser /></Link>
                    </li>
                  ) : (
                    <li className='navigation-item nav-login'>
                      <Link to="/login"><FaRegUser /></Link>
                    </li>
                  )
                }
                <Link to='/cart'>
                  <li className='navigation-item'>
                    <div className='header-cart-link'>
                      <Badge overlap="rectangular" badgeContent={quantity} color="primary">
                        <ShoppingCartOutlined/>
                      </Badge>
                    </div>
                  </li>
                </Link>
              </ul>
            </div>
            <div className='mobile-navigation-content js-mobile-menu-container'>
              <div className='mobile-navigation-right'>
                <Link to='/cart'>
                  <div className='navigation-item'>
                    <div className='header-cart-link'>
                      <Badge overlap="rectangular" badgeContent={quantity} color="primary">
                        <ShoppingCartOutlined/>
                      </Badge>
                    </div>
                  </div>
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav className={`wrapper header-sticky ${showNav ? 'show' : 'hidenav'}`}>
        <div className='containerNavbar'>
          <div className='leftNavbar'>
            <div className='desktop-navigation-content'>
              <ul className='navigation-menu-list'>
                <li className='navigation-item'>
                  <Link to="/shop-prints" className='js-menu-shop-open'>Shop Prints</Link>
                </li>
                {!currentUser?
                  (
                    <li className='navigation-item'>
                      <Link to='/register' className='js-membership-modal-open'>MEMBERSHIP</Link>
                    </li>
                  ) : (
                    ''
                  )
                }
                <li className='navigation-item'>Merch</li>
              </ul>
            </div>
            <div className='mobile-navigation-content js-mobile-menu-container'>
              <div className='mobile-navigation-hamburger-menu js-mobile-menu-toggle'>
                <div className='mobile-navigation-hamburger-menu-bar'></div>
              </div>
            </div>
          </div> 
          <div className='centerNavbar'>
            <Link to='/'>
              <h1 className='logoNavbar'>Framed by Create</h1>
            </Link>
          </div>
          <div className='rightNavbar'>
            <div className='desktop-navigation-content'>
              <ul className='navigation-menu-list'>
                <li className='navigation-item'>
                  <div className='navigation-item-link current-country__toggle'>EN</div>
                </li>
                { currentUser? 
                  (
                    <li className='navigation-item nav-logout'>
                      <Link to='/pages/settings'><FaRegUser /></Link>
                    </li>
                  ) : (
                    <li className='navigation-item nav-login'>
                      <Link to="/login"><FaRegUser /></Link>
                    </li>
                  )
                }
                <Link to='/cart'>
                  <li className='navigation-item'>
                    <div className='header-cart-link'>
                      <Badge overlap="rectangular" badgeContent={quantity} color="primary">
                        <ShoppingCartOutlined/>
                      </Badge>
                    </div>
                  </li>
                </Link>
              </ul>
            </div>
            <div className='mobile-navigation-content js-mobile-menu-container'>
              <div className='mobile-navigation-right'>
                <Link to='/cart'>
                  <div className='navigation-item'>
                    <div className='header-cart-link'>
                      <Badge overlap="rectangular" badgeContent={quantity} color="primary">
                        <ShoppingCartOutlined/>
                      </Badge>
                    </div>
                  </div>
                </Link>              
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Navbar  